<template>
  <section>
    <land-hero-alt
      :title="heroAlt.title"
      :cover="heroAlt.cover"
      :assist-color="heroAlt.assistColor"
      :divisible="heroAlt.divisible"
    />
    <land-intro-icons-ver
      :back-color="about.backColor"
      :assist-color="about.assistColor"
      :header="about.header"
      :features="about.features"
      :button="about.button"
    />
    <land-intro-image-text
      :space="intro1.space"
      :back-color="intro1.backColor"
      :assist-color="intro1.assistColor"
      :header="intro1.header"
      :content="intro1.content"
    />
    <land-intro-image-text
      :space="intro3.space"
      :back-color="intro3.backColor"
      :assist-color="intro3.assistColor"
      :header="intro3.header"
      :content="intro3.content"
    />
    <about-contact
      title="联系方式"
      back-color="grey lighten-4"
      space="50"
    />

    <section-foot />
  </section>
</template>

<script>
  import app from '@/api/co.app'
  import data from '@/data/co.data'

  export default {
    components: {
      AboutContact: () => import('@/pages/sections/about/AboutContact.vue'),
      SectionFoot: () => import('@/pages/sections/Foot.vue')
    },
    metaInfo: { title: '关于我们' },
    data () {
      return {
        userInfo: {},
        heroAlt: {
          ...data.banner.about[0],
          title: '构建物联时代，打造安全体系',
          assistColor: 'primary',
          divisible: false,
          items: [],
        },
        about: {
          backColor: 'white',
          assistColor: 'blue',
          header: {
            title: '南京蓝库软件科技有限公司',
            subtitle: '',
          },
          features: [{
            icon: 'mdi-atom-variant',
            iconColor: 'blue',
            title: '定位',
            subtitle: '安全生产整体方案供应商'
          }, {
            icon: 'mdi-diamond-stone',
            iconColor: 'blue',
            title: '愿景',
            subtitle: '构建物联时代，打造安全体系'
          }, {
            icon: 'mdi-seal-variant',
            iconColor: 'blue',
            title: '使命',
            subtitle: '专注安全，杜绝隐患，智能未来'
          }, {
            icon: 'mdi-broadcast',
            iconColor: 'blue',
            title: '口号',
            subtitle: '技术赋能安全，智能护航发展'
          }],
          button: undefined
        },
        intro1: {
          backColor: 'grey lighten-4',
          assistColor: 'primary',
          space: 50,
          header: {
            title: '',
            subtitle: '',
          },
          content: {
            title: '我们来自哪里？',
            text: '我们是一家致力于物联网技术研发、应用与推广的科技企业。我们专注于为各行各业的客户提供物联网平台、传感器网络、数据分析等方面的服务，以满足他们在智能化、自动化、数字化、高效化等方面的需求。',
            showIndicator: false,
            imageWidth: 560,
            imageHeight: 360,
            images: [
              { src: app.base.res_url + '/img/abt-img-kuapingtai.png', },
              { src: app.base.res_url + '/img/abt-img-anquan.png' },
              { src: app.base.res_url + '/img/abt-img-duoyang.png' }
            ],
            hovers: [{
              color: 'blue',
              name: 'mdi-monitor-cellphone-star',
              text: '跨平台'
            }, {
              color: 'deep-purple ',
              name: 'mdi-security',
              text: '安全性'
            }, {
              color: 'brown',
              name: 'mdi-rhombus-split',
              text: '多样性'
            }],
            button: undefined
          },
        },
        intro3: {
          backColor: 'white',
          assistColor: 'primary',
          space: 50,
          header: {
            title: '',
            subtitle: '',
          },
          content: {
            title: '我们有何不同？',
            text: '我们的优势在于对物联网技术的深入理解和丰富的实践经验，以及一支由资深工程师和专业顾问组成的团队。我们的物联网平台支持设备快速接入、数据高效处理和应用场景灵活定制，已应用于智能酒店、智慧农业、安全生产等多个领域。',
            reversed: true,
            showIndicator: false,
            imageWidth: 560,
            imageHeight: 360,
            images: [
              {
                src: app.base.res_url + '/img/abt-img-butong.png',
              },
            ],
            features: [{
              text: '部署快速',
              icon: 'mdi-lightning-bolt',
              iconColor: 'primary'
            }, {
              text: '行业定制',
              icon: 'mdi-artstation',
              iconColor: 'primary'
            }, {
              text: '管理便捷',
              icon: 'mdi-layers-triple',
              iconColor: 'primary'
            }],
            button: undefined
          },
        },
      }
    },
    created () {
    },
    methods: {
    }
  }
</script>
